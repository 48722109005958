import request from '@/utils/request'


// 查询服务保障列表
export function listGuarantee(query) {
  return request({
    url: '/goods/guarantee/list',
    method: 'get',
    params: query
  })
}

// 查询服务保障分页
export function pageGuarantee(query) {
  return request({
    url: '/goods/guarantee/page',
    method: 'get',
    params: query
  })
}

// 查询服务保障详细
export function getGuarantee(data) {
  return request({
    url: '/goods/guarantee/detail',
    method: 'get',
    params: data
  })
}

// 新增服务保障
export function addGuarantee(data) {
  return request({
    url: '/goods/guarantee/add',
    method: 'post',
    data: data
  })
}

// 修改服务保障
export function updateGuarantee(data) {
  return request({
    url: '/goods/guarantee/edit',
    method: 'post',
    data: data
  })
}

// 删除服务保障
export function delGuarantee(data) {
  return request({
    url: '/goods/guarantee/delete',
    method: 'post',
    data: data
  })
}

export function statusLabel(data) {
  return request({
    url: '/goods/guarantee/status',
    method: 'post',
    data: data
  })
}
